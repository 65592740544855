

export const BottomCard = () => {
	return (<>
		<div className="bottom-card container mx-auto text-center py-5">
			<div className="row justify-content-center">
				<p className="col-auto">Copyright © 2024 Callum Mackenzie</p>
			</div>
		</div>
	</>);
}